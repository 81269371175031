import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 240 240"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#2741E7",
      d: "m161.016 196.991-84.987 31.981s-10.759-3.356-11.351-3.554c-.987-.394-1.678-1.48-1.678-2.961l.099-178.561c0-2.27 1.678-4.64 3.85-5.33l94.265-28.428c.79-.198 1.48-.198 2.171.098.395.198 10.858 3.356 10.858 3.356s-1.678 4.344-1.678 5.232l-7.699 172.837c0 2.27-1.678 4.639-3.85 5.33Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#0F97FF",
      d: "m171.973 200.445-94.167 28.527c-2.073.592-3.85-.691-3.75-3.06l.098-178.562c0-2.27 1.678-4.639 3.85-5.33l94.265-28.526c2.073-.593 3.85.69 3.751 3.06l-.099 178.561c-.098 2.271-1.776 4.64-3.948 5.33Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#E8FDFF",
      d: "M168.025 180.901 81.853 206.96c-1.086.296-1.875-.395-1.875-1.48V60.577c0-1.184.888-2.369 1.875-2.665l86.172-26.059c1.085-.296 1.875.395 1.875 1.48v144.903c0 1.185-.888 2.369-1.875 2.665Z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#2741E7",
      d: "M115.216 41.23c-.592 0-1.086-.394-1.283-.987-.197-.69.197-1.48.888-1.678l17.965-5.429c.691-.197 1.481.198 1.678.889.198.69-.197 1.48-.888 1.678l-17.965 5.429c-.197.098-.296.098-.395.098Z"
    }, null, -1)
  ])))
}
export default { render: render }